.swiper-introductory-2 {
  width: 100% !important;
}

.swiper-slide-introductory-2 {
  display: flex;
  width: 1008px;
  height: 560px;
  background: #fff;
  overflow: hidden;
  border-radius: 30px;
}

@media screen and (max-width: 1024px) {
  .swiper-introductory-2 {
    width: 100% !important;
    display: flex;
  }
  .swiper-slide-introductory-2 {
    display: block;
    width: 100% !important;
    height: auto;
    overflow: initial;
    background: none;
    margin-bottom: 30px;
    border-radius: 20px;
  }
  .swiper-slide-introductory-2 img {
    border-radius: 20px;
  }
}

@media screen and (max-width: 768px) {
  .swiper-introductory-2 {
    width: 100% !important;
    display: flex;
  }

  .swiper-slide-introductory-2 {
    width: 100%;
    height: auto;
  }
  .swiper-slide-introductory-2 img {
    border-radius: 15px;
  }
}
