.quote1 {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 44px;
  height: 33px;
  background: url('../../images/icon/icon-quote1.svg') no-repeat center/contain;
}
.quote2 {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 44px;
  height: 33px;
  background: url('../../images/icon/icon-quote2.svg') no-repeat center/contain;
}

@media screen and (max-width: 1024px) {
  .quote1 {
    width: 27px;
    height: 20px;
    top: 24px;
  }
  .quote2 {
    width: 27px;
    height: 20px;
    bottom: 24px;
  }
}
