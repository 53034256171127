.bgABC {
  padding: 0;
  background: url('../../images/sub2/sub2_sect11.png') no-repeat center/cover;
}

#page_tab .tab_list {
  width: 100%;
  height: 120px;
  background: #fff;
  transition: 0.3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#page_tab.on .tab_list {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0%);
  z-index: 1001;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#page_tab.on {
  margin-bottom: 120px !important;
}

.bg-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background: url('../../images/sub34/map_img.png') no-repeat center/contain,
    #f5f5f5;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  #page_tab {
    height: 60px;
  }
  #page_tab .tab_list {
    gap: 34px;
    height: 60px;
  }
  #page_tab.on {
    height: 60px;
    margin-bottom: 0px !important;
  }
  #page_tab.on .tab_list {
    height: 56px;
  }

  .bg-map {
    height: 323px;
  }
}

@media screen and (max-width: 768px) {
  .bgABC {
    padding: 0;
    background: url('../../images/sub2/sub2_sect11_mo.png') no-repeat center
      bottom/cover;
  }
}
