.scroll-top-btn {
  width: 60px;
  height: 60px;
  bottom: 135px;
  right: 29px;
  border-radius: 30px;
}

@media screen and (max-width: 1024px) {
  .scroll-top-btn {
    width: 55px;
    height: 55px;
    bottom: 115px;
    right: 29px;
  }
}

@media screen and (max-width: 480px) {
  .scroll-top-btn {
    width: 40px;
    height: 40px;
    bottom: 85px;
    right: 15px;
  }
}
