.swiper-fb {
  width: 1260px;
  height: 100%;
  padding: 0px;
}

.swiper-slide-fb {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 100%;
  border-radius: 30px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-fb img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .swiper-fb {
    width: 100%;
    height: 100%;
    padding-bottom: 50px !important;
  }

  .swiper-slide-fb {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide-fb img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    bottom: 0;
    /* position: absolute; */
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 4px;
    margin: 0 3px;
    border-radius: 2px;
  }
}
