.swiper-service-2 {
  width: 100% !important;
}

.swiper-slide-service-2 {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .swiper-service-2 {
   padding-bottom: 20px;
  }
}

/* @media screen and (max-width: 1024px) {
  .swiper-service-2 {
    width: 100vw !important;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .swiper-service-2 {
    width: 100vw !important;
    display: flex;
  }

  .swiper-slide-service-2 {
    width: 280px;
  }
} */
