.line_effect_area .line_effect {
  width: 1px;
  height: 0px;
  margin: auto;
  background-color: #7d7d7d;
  transition-duration: 500ms;
}

.line_effect_area .line_effect.on {
  height: 132px;
}

.cont_img {
  height: 738px;
  width: 100%;
  border-radius: 30px;
  background: url('../../images/main/main_slide1_1.png') no-repeat center/cover;
}

@media screen and (max-width: 1024px) {
  .line_effect_area .line_effect.on {
    height: 60px;
  }
  .cont_img {
    height: 420px;
    border-radius: 20px;
    background: url('../../images/sub2/sub2_sect8_img_mo.png') no-repeat center/cover;
  }
}
