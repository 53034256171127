.swiper-it {
    width: 100% !important;
    height: 100% !important;
    padding: 0px !important;
  }
  
  .swiper-slide-it {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 100%;
    border-radius: 30px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide-it img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 1024px) {
    .swiper-it {
      width: 100% !important;
      height: 100%;
      padding-bottom: 40px !important;
    }
  
    .swiper-slide-it {
      text-align: center;
      background: #fff;
    }
  
    .swiper-slide-it img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .swiper-pagination {
      bottom: 0;
      /* position: absolute; */
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 4px;
      margin: 0 3px;
      border-radius: 2px;
    }
  }
  