.bgSelect {
  position: relative;
  height: 600px;
  background: url('../../images/sub2/sub2_bg.png') no-repeat center/cover;
}

@media screen and (max-width: 1024px) {
  .bgSelect {
    background: url('../../images/sub2/sub2_bg_mo.png') no-repeat center/cover;
    height: 535px;
  }
}
