.bg-1 {
  height: 700px;
  border-radius: 30px;
  background: url('../../images/aboutus_img.jpg') no-repeat center right/cover;
  width: 100%;
}
.timeline-card {
  width: 150px;
}

@media screen and (max-width: 1024px) {
  .bg-1 {
    width: 100%;
    height: 240px;
    border-radius: 20px;
  }
  .timeline-card {
    width: 60px;
    height: 60px;
  }
}
