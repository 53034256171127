.swiper-button-prev {
  width: 90px;
  height: 90px;
  left: 6.25%;
  background: url('../../images/icon/slide-arrow-left.svg') no-repeat
    center/contain;
  border-radius: 100px;
  transition: 0.3s;
  cursor: pointer;
}
.swiper-button-prev::after {
  content: '';
}
.swiper-button-prev:hover {
  background-image: url('../../images/icon/slide-arrow-left-hover.svg');
}
.swiper-button-prev.swiper-button-disabled {
  background-image: url('../../images/icon/slide-arrow-left-inactive.svg');
  opacity: 1;
}

.swiper-button-next {
  width: 90px;
  height: 90px;
  right: 6.25%;
  background: url('../../images/icon/slide-arrow-right.svg') no-repeat
    center/contain;
  border-radius: 100px;
  transition: 0.3s;
  cursor: pointer;
}
.swiper-button-next::after {
  content: '';
}
.swiper-button-next:hover {
  background-image: url('../../images/icon/slide-arrow-right-hover.svg');
}
.swiper-button-next.swiper-button-disabled {
  background-image: url('../../images/icon/slide-arrow-right-inactive.svg');
  opacity: 1;
}
