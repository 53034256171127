::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #d6dee1;
  border: 6px solid transparent;
  border-radius: 20px;
}

::-webkit-scrollbar {
  scrollbar-gutter: unset;
  width: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
