.swiper-bespoke-2 {
  width: 100% !important;
}

.swiper-slide-bespoke-2 {
  width: 100%;
  height: auto;
}

 @media screen and (max-width: 1024px) {
  .swiper-slide-bespoke-2 {
    margin-bottom: 15px;
  }
}

/* {
@media screen and (max-width: 768px) {
  .swiper-service-2 {
    width: 100vw !important;
    display: flex;
  }

  .swiper-slide-service-2 {
    width: 280px;
  }
} */
